.parent,
.child,
.titleTile {
   transition: 1s ease-in-out all;
}

.parent:hover .child,
.parent:focus .child {
   transform: scale(1.2);
   transition: 1s ease-in-out all;
}

.parent:hover {
   box-shadow: 2px 2px 10px -1px;
   transition: .5s ease-in-out all;
}

.parent:hover .titleTile {
   bottom: 0;
   transition: .5s ease-in-out all;
}
.projectHead {
   position: relative;
}

.projectHead:nth-child(odd)::before {
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   /* ... */
   background:
      linear-gradient(-90deg, rgba(214, 192, 155, .7), transparent),
      url(https://grainy-gradients.vercel.app/noise.svg);
}

.projectHead:nth-child(even)::before {
   content: '';
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   /* ... */
   background:
      linear-gradient(-90deg, rgba(214, 192, 155, .7), transparent),
      url(https://grainy-gradients.vercel.app/noise.svg);
}