@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;700&family=Ubuntu:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fondamento:ital@0;1&display=swap');
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
      font-family: 'bauhaus';
      src: url('/public/fonts/Bauhaus93.ttf') format('truetype');
    }
        @font-face {
          font-family: 'broadway';
          src: url('/public/fonts/BroadwayRegular.ttf') format('truetype');
        }
}



/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*/

.slick-track {
  display: flex !important;
  align-items: center;
}
.slick-custom li {
  width: 80px !important;
  height: 50px !important;
}

.slick-custom .slick-active {
  border: 4px solid white;
}
.navicon {
  display:block; 
  border:none;
  outline:none;
  background-color:transparent;
}

.navicon__holder {
    display:block;
    cursor:pointer;
  }

.navicon__line {
    position:relative;
    display:block;
    width:26px;
    height:3px;
    margin-bottom:5px;
    background-color:rgb(228 228 231);
    transition:all 0.25s cubic-bezier(0.57, 0.28, 0.25, 0.69);
  }

.navicon__line:last-of-type {
      margin-bottom:0;
    }

.navicon--toggle .navicon__line:first-of-type {
    transform:
      rotate(45deg)
      translateY(6px)
      translateX(6px);
  }

.navicon--toggle .navicon__line:nth-of-type(2) {
    opacity:0.0;
    transform:scale(0);
  }

.navicon--toggle .navicon__line:last-of-type {
    transform:
      rotate(-45deg)
      translateY(-5px)
      translateX(5px);
  }
.custom::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}